import React, { useEffect } from 'react';
import { Content } from '@/common/components/content';
import { Layout } from '@/common/components/layout';
import { SEO } from '@/common/components/seo';
import { useOffline } from '@/common/hooks/offline-hook';
import { mapMaybe } from '@/common/utils/map-maybe';
import { jsx as ___EmotionJSX } from "@emotion/core";
var query = "1973259868";

var OfflinePage = function OfflinePage(_ref) {
  var _data$cms, _data$cms$offlinePage, _data$cms$offlinePage2;

  var data = _ref.data;
  var offline = useOffline();
  useEffect(function () {
    if (!offline && typeof window !== 'undefined') {
      window.location.reload();
    }
  }, [offline]);
  var attributes = data === null || data === void 0 ? void 0 : (_data$cms = data.cms) === null || _data$cms === void 0 ? void 0 : (_data$cms$offlinePage = _data$cms.offlinePage) === null || _data$cms$offlinePage === void 0 ? void 0 : (_data$cms$offlinePage2 = _data$cms$offlinePage.data) === null || _data$cms$offlinePage2 === void 0 ? void 0 : _data$cms$offlinePage2.attributes;
  var pageContent = attributes === null || attributes === void 0 ? void 0 : attributes.content;
  var pageTitle = attributes === null || attributes === void 0 ? void 0 : attributes.title;
  return ___EmotionJSX(Layout, null, ___EmotionJSX(SEO, {
    title: pageTitle !== null && pageTitle !== void 0 ? pageTitle : 'Offline',
    meta: [{
      name: 'robots',
      content: 'noindex'
    }]
  }), pageContent ? ___EmotionJSX(Content, {
    contentBlocks: mapMaybe(pageContent)
  }) : null);
};

OfflinePage.displayName = "OfflinePage";
export { OfflinePage as default, query };